.termsservice {
    margin: 0 auto;
    padding: 40px;
    background-color: #fff; /* White background for content */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    color: #333; /* Dark gray for text */
    background-color: #f5f5f5; /* Light background color */
}

.termsservice h1, 
.termsservice h2, 
.termsservice h3 {
    color: #1a002e; /* Use a darker purple for headings */
}

.termsservice p {
    line-height: 1.8;
    margin-bottom: 20px;
}

.termsservice ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.termsservice ul li {
    margin-bottom: 10px;
    font-size: 16px;
}

.termsservice a {
    color: #0077cc; /* Bright blue for links */
    text-decoration: none;
}

.termsservice a:hover {
    text-decoration: underline;
}

.termsservice table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.termsservice table, 
.termsservice th, 
.termsservice td {
    border: 1px solid #ccc; /* Light border for table */
}

.termsservice th, 
.termsservice td {
    padding: 12px;
    text-align: left;
    font-size: 16px;
}

.termsservice th {
    background-color: #f0f0f0; /* Slightly darker background for table header */
}

.termsservice td {
    background-color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .termsservice {
        padding: 20px;
    }

    .termsservice table, 
    .termsservice th, 
    .termsservice td {
        font-size: 14px;
    }

    .termsservice p {
        font-size: 16px;
    }

    .termsservice ul li {
        font-size: 14px;
    }
}