/* Container for the Modern Slavery Statement */
.modern-slavery-statement {
    background-color: #fff;
    color: #2f005d;
    padding: 40px;
    max-width: 800px;
    margin: auto;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

.modern-slavery-statement h1 {
    font-size: 2.5em;
    color: #4e3a77;
    text-align: center;
    margin-bottom: 20px;
}

.modern-slavery-statement h2 {
    font-size: 1.8em;
    color: #333;
    margin-top: 30px;
    margin-bottom: 15px;
}

.modern-slavery-statement p,
.modern-slavery-statement ul {
    font-size: 1em;
    color: #555;
    margin-bottom: 15px;
}

.modern-slavery-statement ul {
    list-style-type: disc;
    padding-left: 20px;
}

.modern-slavery-statement ul li {
    margin-bottom: 8px;
}

.modern-slavery-statement p:last-of-type {
    font-weight: bold;
    color: #2f005d;
}

/* Signature Section */
.signature-section {
    margin-top: 15px;
}

.signature-section label {
    font-weight: bold;
    color: #333;
}

.signature-section span {
    color: #555;
    margin-left: 5px;
}

.signature-image {
    text-align: left;
    margin-top: 15px;
}

.signature-image img {
    width: 150px; /* Adjust the width of the signature image */
    height: auto;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .modern-slavery-statement {
        padding: 20px;
    }

    .modern-slavery-statement h1 {
        font-size: 2em;
    }

    .modern-slavery-statement h2 {
        font-size: 1.6em;
    }

    .modern-slavery-statement p,
    .modern-slavery-statement ul {
        font-size: 0.9em;
    }
}
