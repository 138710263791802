/* Base Styles */
body {
    font-family: 'Inter', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .carbon-plan-container {
    max-width: 800px;
    background-color: #fff;
    padding: 30px;
    margin: 40px auto;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* .carbon-plan-container h1, h2, h3 {
    color: #4e3a77;
    font-weight: 600;
    margin: 20px 0;
  } */
  
  .carbon-plan-container h1 {
    font-size: 1.8em;
    border-bottom: 2px solid #4e3a77;
    padding-bottom: 10px;
  }
  
  .carbon-plan-container h2 {
    font-size: 1.4em;
  }
  
  .carbon-plan-container h3 {
    font-size: 1.2em;
    color: #333;
  }
  
  .carbon-plan-container .section {
    margin-bottom: 25px;
  }
  
  .carbon-plan-container p, ul {
    line-height: 1.6;
    text-align: left;
    color: #333;
    margin: 10px 0;
  }
  
  .carbon-plan-container ul {
    padding-left: 20px;
  }
  
  .carbon-plan-container ul li {
    margin-bottom: 8px;
  }
  
  .carbon-plan-container strong {
    font-weight: 600;
    color: #333;
  }
  
  .carbon-plan-container .sign-off {
    list-style-type: none;
    padding-left: 0;
    margin-top: 15px;
  }
  
  .carbon-plan-container .sign-off li {
    margin-bottom: 8px;
  }
  
  .carbon-plan-container .sign-off a {
    color: #4e3a77;
    text-decoration: none;
  }
  
  .carbon-plan-container  .sign-off a:hover {
    text-decoration: underline;
  }

  .carbon-plan-container .emissions-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .carbon-plan-container .emissions-table th, .emissions-table td {
    padding: 12px 15px; /* Adjusts the padding for clarity */
    border: 1px solid #333;
    text-align: left;
    font-size: 16px;
  }
  
  .carbon-plan-container .emissions-table th {
    background-color: #4e3a77; /* Matches header/footer background */
    color: white;
    font-weight: bold;
  }
  
  .carbon-plan-container .emissions-table td {
    background-color: #f5f5f5; /* Light background for readability */
    color: #333;
  }
  
  .carbon-plan-container .emissions-table tr:last-child td {
    font-weight: bold; /* Highlights the Total Emissions row */
  }
  
  
  /* Mobile Styles */
  @media (max-width: 600px) {
    .carbon-plan-container {
      padding: 20px;
      margin: 20px;
      box-shadow: none; /* Simplify shadow for mobile */
      border: none; /* Remove border for a cleaner look */
    }
  
    .carbon-plan-container h1 {
      font-size: 1.5em;
      padding-bottom: 8px;
    }
  
    .carbon-plan-container h2 {
      font-size: 1.2em;
    }
  
    .carbon-plan-container h3 {
      font-size: 1.1em;
    }
  
    .carbon-plan-container p, ul {
      font-size: 0.95em;
    }
  
    .carbon-plan-container .section {
      margin-bottom: 20px;
    }
  }
  
 