/* Tile.css */

.tile {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #1a002e;
    font-family: 'Inter', sans-serif;
    z-index: -1;
  }


  .tile img {
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    width: 100%;
    /* object-fit: cover; */
    z-index: -1; /* Ensure the image is behind the content */
  }
  
  .tile-content {
    max-width: 1000px;
    padding: 20px;
  }
  
  .tile h1 {
    font-size: 5.1rem;
    margin-bottom: 20px;
    font-family: "Inter Tight", sans-serif;
    font-weight: 500;
  }

  .tile h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
  }
  
  .tile p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  


.tile button {
    background-color: #2f005d; /* Dark purple background */
    color: white;
    border: none;
    padding: 20px; 
    border-radius: 25px; /* 50% border radius for pill shape */
    font-size: 16px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
  }
  
  .tile button:hover {
    background-color: #4e3a77; /* Slightly lighter purple on hover */
  }
  

  @media (max-width: 768px) {
    .tile h1 {
      font-size: 3rem;
    }
  
      .tile h2 {
        font-size: 1.3em;
      }


  }