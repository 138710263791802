/* Privacy Notice Styles */


.privacy-notice {
    margin: 0 auto;
    padding: 40px;
    background-color: #fff; /* White background for content */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    color: #333; /* Dark gray for text */
    background-color: #f5f5f5; /* Light background color */
    margin: 0;
    padding: 20px;
}

.privacy-notice h1, h2, h3 {
    color: #1a002e; /* Use a darker purple for headings */
}

.privacy-notice p {
    line-height: 1.8;
    margin-bottom: 20px;
}

.privacy-notice table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.privacy-notice table, th, td {
    border: 1px solid #ccc; /* Light border for table */
}

.privacy-notice th, td {
    padding: 12px;
    text-align: left;
    font-size: 16px;
}

.privacy-notice th {
    background-color: #f0f0f0; /* Slightly darker background for table header */
}

.privacy-notice td {
    background-color: #ffffff;
}

.privacy-notice a {
    color: #0077cc; /* Bright blue for links */
    text-decoration: none;
}

.privacy-notice a:hover {
    text-decoration: underline;
}



@media (max-width: 768px) {
    .privacy-notice {
        padding: 20px;
    }

    .privacy-notice table, th, td {
        font-size: 14px;
    }

    .privacy-notice p {
        font-size: 16px;
    }
}
