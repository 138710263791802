.footer {
    font-family: "Be Vietnam Pro", serif;
    background-color: #0b0013; /* Light grey background */
    text-align: center;
    padding: 20px;
    color: rgb(255, 255, 255);
  }
  
  .footer a {
    text-decoration: none;
    color: #007bff; /* Blue link color */
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    font-size: 20px; 
  }

  @media (max-width: 768px) {
    .footer p {
      font-size: 10px;
    }

  }