body {
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

header, footer {
  background-color: #4e3a77;
  color: white;
  padding: 20px;
  text-align: center;
}


button {
  background-color: #2f005d;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

button:hover {
  background-color: #4e3a77;
}

/* Tile or Slide Section Styles */

.line {
  background-color:  #9da0a6;
  flex: none;
  opacity: 0.2;
  height: 2px;
  overflow: hidden;
  position: relative;
  margin: 70px 0; 
  width: 100%;
}

.signature-image img {
  height: 100px; 
}